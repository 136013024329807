import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';

import ContestCountdown from '../ContestCountdown';
import Menu from './Menu.js';
import SidebarLogo from '../SidebarLogo';
import { useContest } from '../../../ContestContext';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => {
    return (<li className={ isActive ? 'active' : '' }>
            <Link to={item.path} title={item.name}>
                {item.label && !!item.label.value && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
                {item.icon && <em className={item.icon}></em>}
                <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
            </Link>
        </li>
    )
}

const AdminSidebar = () => {
    const location = useLocation();
    
    const startTimes = useContest().startTimes;
    const endTimes = useContest().endTimes;
    const activeContests = useContest().activeContests;

    const unanswered = useContest().unansweredQuestions;

    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) === 0)
    }

    return (
        <aside className='aside-container'>
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    <ul className="sidebar-nav">
                        <Link to="/">
                            <SidebarLogo />
                        </Link>

                        {activeContests.map(c => {
                            return <ContestCountdown key={Date.now()} startTime={startTimes.get(c)} endTime={endTimes.get(c)} userrole={'admin'} />;
                        })}

                        <SidebarItemHeader item={Menu.items.menu} />
                        <SidebarItem isActive={routeActive(Menu.items.home.path)} item={Menu.items.home} />
                        <SidebarItem isActive={routeActive(Menu.items.contests.path)} item={Menu.items.contests} />
                        <SidebarItem isActive={routeActive(Menu.items.tasks.path)} item={Menu.items.tasks} />
                        <SidebarItem isActive={routeActive(Menu.items.submissions.path)} item={Menu.items.submissions} />
                        {/*<SidebarItem isActive={routeActive(Menu.items.scoring.path)} item={Menu.items.scoring} />*/}

                        <SidebarItem isActive={routeActive(Menu.items.standing.path)} item={Menu.items.standing} />


                        <SidebarItem isActive={routeActive(Menu.items.communication.path)}
                        item={{...Menu.items.communication, label: { value: unanswered, color: 'danger' }}} />
                        
                        <SidebarItem isActive={routeActive(Menu.items.users.path)} item={Menu.items.users} />

                        <SidebarItemHeader item={Menu.items.documentation} />
                        <li>
                            <Link target="_blank" to="/docs/en/index.html">
                                <em className="fas fa-book"></em><span>C++ Документация</span>
                            </Link>
                        </li>
                        {<SidebarItem isActive={routeActive(Menu.items.systemInfo.path)} item={Menu.items.systemInfo} />}
                    </ul>
                </nav>
            </div>
        </aside>
    );
    
}

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(AdminSidebar)));
